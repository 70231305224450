import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="londra1849">
<h1>Professore, deputato e giornalista</h1>
<p>Nel 1849 Antonio rientra a Londra e trova un posto di docente all’University College, dove insegnerà per dieci anni esatti (fino al 1859).</p>
<p>Negli anni successivi dà alla luce diverse opere di carattere storico, letterario e linguistico, fino al 1854 con lo pseudonimo di Luigi Mariotti, e in seguito con il proprio nome di battesimo.<br />
Tra queste opere si ricordano:</p>
<ul>
<li>la grande grammatica italiana &quot;Mariotti’s Italian Grammar&quot; (1851) per cui riceve le lodi dell'Athenaeum Club - di cui inseguito Antonio diverrà membro - e che verrà pubblicata in ben 14 edizioni fino al 1883)</li>
<li>le riflessioni personali e politiche sull’esperienza vissuta in Italia nel 1848, &quot;Italy in 1848&quot; (1851)</li>
</ul>
<p>In questi anni si impegna anche in un progetto di pubblicistica che mira a descrivere la situazione interna del Regno di Sardegna e a rafforzarne la posizione internazionale: pubblica così &quot;Historical Memoir of Fra’ Dolcino and his Times&quot; (1853), di chiaro tono antiromano, &quot;Castellamonte. An Autobiographical Sketch illustrative of Italian Life during the Insurrection of 1831&quot; (1854) e &quot;History of Piedmont&quot; (1855-56).<br />
Quest’ultimo lavoro viene molto apprezzato dagli ambienti governativi torinesi, ma meno dagli ex compagni repubblicani che lo giudicano il lavoro di un uomo venduto alla causa monarchica.</p>
<p>Antonio decide, anche in seguito alle richieste dello stesso Cavour, di candidarsi per un seggio alla Camera subalpina di Torino: il 20 agosto 1854 viene eletto e si trasferisce a Castellamonte, luogo di origine della famiglia paterna, per seguire i lavori parlamentari.</p>
<p>Tra i suoi interventi più celebri si ricordano quelli a sostegno dell’intervento piemontese nella Guerra di Crimea (1853-1856) a fianco di ottomani, francesi e inglesi.<br />
In questi anni svolge anche il lavoro di giornalista per i quotidiani sabaudi “Il Cimento, “Il Piemonte” (quest’ultimo diretto da Luigi Carlo Farini) e il quotidiano inglese “Daily News”.</p>
<p>Verso la fine del 1856 la carriera parlamentare di Antonio si interrompe bruscamente dopo che, all’interno dell’opera &quot;History of Piedmont&quot;, ha accennato al mancato regicidio del 1833 accusando Antonio Mazzini di esserne il mandante.</p>
<p>Il Mazzini si difende e costringe Antonio ad ammettere le proprie responsabilità: nell’ottobre rassegna le sue dimissioni, restituisce la croce di cavaliere mauriziano da poco conferitagli e ritorna a vivere in pianta stabile a Londra.</p>
<p>Nel frattempo, nel 1855, la moglie Juliet Schunk, madre dei suoi primi due figli, scompare prematuramente; tre anni dopo (1858) Antonio si risposa con la vedova irlandese Ann Johnstone, con la quale ha un terzo figlio, Guido Arduino.</p>
<p>Nel 1859, dopo essersi licenziato dal quotidiano “Daily News”, viene assunto come corrispondente estero dal “Times”: come prima missione viene mandato in Italia per seguire le vicende della Seconda Guerra d’Indipendenza.</p>
<h2>Link per approfondire</h2>
<p><a href="https://en.wikipedia.org/wiki/Athenaeum_Club,_London">“Athenaeum Club”</a></p>
<p><a href="https://www.treccani.it/enciclopedia/fra-dolcino/">“Fra Dolcino”</a></p>
<p><a href="https://www.treccani.it/enciclopedia/guerra-di-crimea_%28Dizionario-di-Storia%29/">“Guerra di Crimea”</a></p>
<p><a href="https://www.treccani.it/enciclopedia/regno-di-sardegna_%28Enciclopedia-dei-ragazzi%29/">“Regno di Sardegna”</a></p>
<p><a href="https://it.wikipedia.org/wiki/Seconda_guerra_d%27indipendenza_italiana">“Seconda guerra d’indipendenza italiana”</a></p>
<p><a href="https://www.treccani.it/enciclopedia/the-times/">“The Times”</a></p>
<p><a href="https://it.wikipedia.org/wiki/University_College_(Londra)"> “University College di Londra”</a></p>

	</PaginaTesto>
}